import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Link, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import * as yup from 'yup';
import { RiMailLine, RiLockPasswordLine } from 'react-icons/ri';
import Typography from '@mui/material/Typography';
import { useAuth, FormInput, Loader, AuthLayout, Message } from '@omnigenbiodata/ui';
import { MESSAGES } from '../../../../core/constants/forms.constants';
import ROUTES from '../../../../core/constants/routes.constants';
import { AUTH_CHALLENGES } from '../../../../core/constants/aws.constants';
import Buttons from '../../../../components/forms/Buttons';

const validationSchema = yup.object().shape({
  email: yup.string().email(MESSAGES.emailInvalid).required(MESSAGES.emailRequired),
  password: yup.string().required(MESSAGES.passwordRequired),
});

function Login() {
  const { t } = useTranslation('portal');
  const [submitCount, setSubmitCount] = useState(0);
  const { resetUser, hasError, isBusy, forgotPasswordHasSuccess, signIn, challengeName } = useAuth();

  useEffect(() => {
    if (submitCount === 0) {
      resetUser();
      setSubmitCount(submitCount + 1);
    }
  }, [resetUser, submitCount]);

  const formik = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema,
    onSubmit: (values) => {
      signIn(values.email, values.password);
    },
  });

  const handleCancel = () => {
    return <Navigate to={ROUTES.root} />;
  };

  if (
    !isBusy &&
    (challengeName === AUTH_CHALLENGES.MFA_SETUP || challengeName === AUTH_CHALLENGES.SOFTWARE_TOKEN_MFA) &&
    submitCount > 0
  ) {
    return <Navigate to={ROUTES.authMfa} />;
  }

  if (
    !isBusy &&
    (challengeName === AUTH_CHALLENGES.NEW_PASSWORD || challengeName === AUTH_CHALLENGES.NEW_PASSWORD_REQUIRED) &&
    submitCount > 0
  ) {
    return <Navigate to={ROUTES.authNewPassword} />;
  }
  return (
    <AuthLayout
      title={t('signin.title')}
      text={t('signin.text')}
      hasError={hasError}
      errorTitle={t('alerts.loginFailed.title')}
      errorText={t('alerts.loginFailed.text')}
    >
      <form onSubmit={formik.handleSubmit}>
        {forgotPasswordHasSuccess && <Message>{t('alerts.resetPasswordSuccess')}</Message>}

        <Box mb={8}>
          <FormInput
            error={formik.errors.email}
            name="email"
            label={t('forms.dmEmail')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.email}
            value={formik.values.email}
            startAdornment={<RiMailLine fontSize="large" />}
          />
          <FormInput
            error={formik.errors.password}
            name="password"
            label={t('forms.password')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.password}
            value={formik.values.password}
            type="password"
            startAdornment={<RiLockPasswordLine fontSize="large" />}
          />
          <Link to={`${ROUTES.authResetPassword}`}>
            <Typography paragraph variant="body1" align="right" color="textPrimary">
              {t('buttons.forgotPassword')}
            </Typography>
          </Link>
        </Box>
        <Buttons
          onBack={handleCancel}
          onForward={formik.submitForm}
          backLabel={t('buttons.back') as string}
          forwardLabel={t('buttons.signIn')}
        />
      </form>
      <Loader isVisible={isBusy} label={t('loaders.check') as string} />
    </AuthLayout>
  );
}

export default Login;
